import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import HeaderJumbotron from 'components/headerJumbotron';
import IconSection from 'components/iconSection';
import ZetaSection from 'components/zetaSection';
import RoomsBox from 'components/roomsBox';
import MapsSection from 'components/mapsSection';
import Footer from 'components/footer';
import { Container, Row, Col, Button } from 'reactstrap';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
const Index = ({ location }) => (
  <Layout>
    {/* <HeaderJumbotron headerText="Comfortable stay, at the heart of Mumbai" /> */}
    <Container>
      <div style={{position:"relative",paddingTop: "56.25%",marginTop:"100px"}}>

  
    <iframe style={{position:"absolute",top:"0",left:"0",width:"100%",height:"600px",border:"0"}} title="review form"  src="https://docs.google.com/forms/d/e/1FAIpQLSfVCrQoVZASZM_Crg3YhixZ4BYaovMK1B_liNXv8TDLwXrs6A/viewform?embedded=true" width="400" height="1000">Loading…</iframe>
        </div>
      
    </Container>
    {/* <Footer /> */}
  </Layout>
);

// Index.propTypes = {
//   data: PropTypes.object.isRequired,
// };

export default Index;

export const query = graphql`
  query {
    royal: file(
      relativePath: { eq: "images/gallery/royal-hotel-room-bandra-mumbai.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    superior: file(
      relativePath: {
        eq: "images/gallery/premium-hotel-room-bandra-mumbai.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bwsl: file(
      relativePath: { eq: "images/gallery/bandra-worli-sea-link.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    concept: file(relativePath: { eq: "images/gallery/concept.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    file(relativePath: { eq: "images/gallery/bandra-worli-sea-link.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
